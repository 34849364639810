<template>
  <v-container id="files" fluid tag="section">
    <h1 v-if="userType == userTypeEnum.Investor" class="extract-investor-title">
      {{ $t("investment_data") }}
    </h1>
    <p
      v-if="userType == userTypeEnum.Investor"
      class="extract-investor-sub-title"
    >
      {{ $t("check_your_extract_sub_title") }}
    </p>

    <div
      class="reprocess-extract-container"
      v-if="listOfExtracts.length > 0 && userType === userTypeEnum.Admin"
    >
      <div class="reprocess-extract-input-container">
        <v-select
          label="Select"
          :items="listOfExtracts"
          :item-text="text"
          variant="solo"
          class="mt-6"
          v-model="selectedExtract"
          return-object
        >
        </v-select>
      </div>
      <div class="reprocess-extract-button-container">
        <v-btn
          :loading="reprocessExtractLoading"
          @click="reprocessExtracts()"
          color="primary"
          class="ma-2"
          :disabled="!selectedExtract"
        >
          {{ $t("process_extract") }}
          <v-icon v-if="userType === userTypeEnum.Admin"
            >mdi-file-arrow-up-down</v-icon
          >
        </v-btn>
        <v-btn
          :loading="reprocessExtractLoading"
          @click="reprocessIRExtract()"
          color="primary"
          class="ma-2"
          :disabled="listOfExtracts.length === 0"
        >
          {{ $t("process_extract_ir") }}
          <v-icon v-if="userType === userTypeEnum.Admin"
            >mdi-file-arrow-up-down</v-icon
          >
        </v-btn>


      </div>
    </div>

    <div
      v-if="
        userType === userTypeEnum.Admin && !loading && investorInvestmentsReport
      "
    >
      <div
        :style="`background-color: var(--secondary); border-radius: 5px`"
        class="mt-4"
      >
        <p style="color: var(--white); font-weight: normal" class="pa-2 mb-0">
          {{ $t("investments_report") }}
        </p>
      </div>
      <v-simple-table>
        <thead>
          <tr>
            <th>
              {{ $t("name") }}
            </th>
            <th>{{ $t("download") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="table-extract-td">
              {{ investorInvestmentsReport.reportName }}
            </td>
            <td class="table-extract-td">
              <v-btn
                @click="handleDownloadExtract(investorInvestmentsReport.id)"
                icon
                color="primary"
                min-width="0"
                small
                :loading="
                  extractDownloadLoading &&
                  investorInvestmentsReport.id === extractToDownload
                "
              >
                <v-icon small>mdi-download</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div
      v-if="userType === userTypeEnum.Admin && !loading"
      :style="`background-color: var(--secondary); border-radius: 5px`"
      class="mt-4"
    >
      <p style="color: var(--white); font-weight: normal" class="pa-2 mb-0">
        {{ $tc("extract", 2) }}
      </p>
    </div>
    <v-simple-table
      v-if="!loading"
      :class="{
        'extract-table': userType === userTypeEnum.Investor,
      }"
    >
      <thead
        :class="{
          'extract-table-thead': userType === userTypeEnum.Investor,
        }"
      >
        <tr v-if="reportType === ReportTypeEnum.Annual">
          <th>
            {{ $t("name") }}
          </th>
          <th>{{ userType === userTypeEnum.Admin ? $t("download") : "" }}</th>
        </tr>
        <tr v-else-if="userType === userTypeEnum.Admin">
          <th>
            {{ $t("name") }}
          </th>
          <th>
            {{ $t("company_single") }}
          </th>
          <th>{{ $t("download") }}</th>
        </tr>
        <tr v-else-if="userType === userTypeEnum.Investor">
          <th>
            {{ $t("vehicle_title_single") }}
          </th>
          <th>
            {{ $t("rounds_time_title") }}
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody v-if="reportType === ReportTypeEnum.Annual">
        <tr v-for="(report, i) in reports" :key="i">
          <td class="table-extract-td table-extract-td-vehicle">
            <font-awesome-icon
              icon="fa-solid fa-file-invoice"
              class="table-icon-file"
              v-if="userType === userTypeEnum.Investor"
              @click="handleDownloadExtract(report.id)"
            />
            {{ report.reportName }}
          </td>
          <td class="table-extract-td">
            <v-btn
              @click="handleDownloadExtract(report.id)"
              v-if="userType === userTypeEnum.Admin"
              icon
              color="primary"
              min-width="0"
              small
              :loading="
                extractDownloadLoading && report.id === extractToDownload
              "
            >
              <v-icon small v-if="userType === userTypeEnum.Admin"
                >mdi-download</v-icon
              >
            </v-btn>
            <span
              @click="handleDownloadExtract(report.id)"
              class="table-extract-td-link-download"
              v-if="userType === userTypeEnum.Investor"
            >
              <v-progress-circular
                v-if="extractDownloadLoading && report.id === extractToDownload"
                indeterminate
                size="20"
                color="primary"
              ></v-progress-circular>
              <span v-else>{{ $t("download") }}</span>
            </span>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="userType === userTypeEnum.Admin">
        <tr v-for="(report, i) in reports" :key="i">
          <td class="table-extract-td table-extract-td-vehicle">
            {{ report.reportName }}
          </td>
          <td class="table-extract-td">
            {{ report.companyName }}
          </td>
          <td class="table-extract-td">
            <v-btn
              @click="handleDownloadExtract(report.id)"
              icon
              color="primary"
              min-width="0"
              small
              :loading="
                extractDownloadLoading && report.id === extractToDownload
              "
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="userType === userTypeEnum.Investor">
        <tr v-for="(report, i) in reports" :key="i">
          <td class="table-extract-td table-extract-td-vehicle">
            <font-awesome-icon
              icon="fa-solid fa-file-invoice"
              class="table-icon-file"
              @click="handleDownloadExtract(report.id)"
            />
            {{
              userType === userTypeEnum.Investor
                ? report.vehicleName
                : report.reportName
            }}
          </td>
          <td class="table-extract-td">
            {{ report.reportDate }}
          </td>
          <td class="table-extract-td">
            <span
              @click="handleDownloadExtract(report.id)"
              class="table-extract-td-link-download"
            >
              <v-progress-circular
                v-if="extractDownloadLoading && report.id === extractToDownload"
                indeterminate
                size="20"
                color="primary"
              ></v-progress-circular>
              <span v-else>{{ $t("download") }}</span>
            </span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="item-center" v-else>
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>

    <!-- estilos responsivo para mostrar os cards quando for investidor -->
    <ul
      class="extract-investor-list"
      v-if="
        userType === userTypeEnum.Investor &&
        reportType === ReportTypeEnum.Annual
      "
    >
      <li
        class="extract-investor-item"
        v-for="(report, i) in reports"
        :key="i"
        @click="handleDownloadExtract(report.id)"
      >
        <span class="extract-investor-vehicle">{{ report.reportName }}</span>
        <v-progress-circular
          v-if="extractDownloadLoading && report.id === extractToDownload"
          indeterminate
          size="20"
          color="primary"
        ></v-progress-circular>
        <span class="extract-investor-download" v-else>{{
          $t("download")
        }}</span>
      </li>
    </ul>
    <ul
      class="extract-investor-list"
      v-else-if="userType === userTypeEnum.Investor"
    >
      <li
        class="extract-investor-item"
        v-for="(report, i) in reports"
        :key="i"
        @click="handleDownloadExtract(report.id)"
      >
        <span class="extract-investor-vehicle">{{ report.vehicleName }}</span>
        <span class="extract-investor-date">{{ report.reportDate }}</span>
        <v-progress-circular
          v-if="extractDownloadLoading && report.id === extractToDownload"
          indeterminate
          size="20"
          color="primary"
        ></v-progress-circular>
        <span class="extract-investor-download" v-else>{{
          $t("download")
        }}</span>
      </li>
    </ul>
    <v-pagination
      color="primary"
      @input="changePage"
      v-model="page"
      :length="pageCount"
      class="mt-6"
    >
    </v-pagination>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { ReportTypeEnum } from "../../../shared/enums/ReportTypeEnum";

export default {
  name: "DashboardExtractTable",
  components: {},
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    reports: [],
    userType: null,
    userTypeEnum: UserTypeEnum,
    page: 1,
    pageCount: 1,
    loading: false,
    extractDownloadLoading: false,
    extractToDownload: null,
    selectedExtract: null,
    listOfExtracts: [],
    reprocessExtractLoading: false,
    ReportTypeEnum,
    investorInvestmentsReport: null,
  }),
  props: {
    companyId: Number,
    userId: String,
    reportType: Number,
  },
  async created() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.userType = user.type;

    await this.getExtractsByPage();
    this.getDataAtual();

    if (this.userType === this.userTypeEnum.Admin) {
      await this.listExtractsByInvestor();
      await this.getInvestorInvestmentsReport();
    }
  },
  computed: {},
  methods: {
    async getInvestorInvestmentsReport() {
      this.loading = true;
      await this.apiService
        .getRequest(
          `investmentreport/investor/investments-report/${this.userId}`
        )
        .then((resp) => {
          this.investorInvestmentsReport = resp.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });
      this.loading = false;
    },
    async handleDownloadExtract(extractId) {
      this.extractDownloadLoading = true;
      this.extractToDownload = extractId;

      await this.apiService
        .getRequest(`investmentreport/${extractId}`)
        .then((extract) => {
          extract = extract.content;
          // alinhar se vai vir o nome do arquivo
          let fileName = extract.fileName;
          // alinhar se vai vir o content dessa forma
          extract =
            "data:application/octet-stream;base64," + extract.fileContent;
          const link = document.createElement("a");
          link.href = extract;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
          return err;
        });
      this.extractDownloadLoading = false;
    },
    changePage() {
      this.getExtractsByPage();
    },

    async listExtractsByInvestor() {
      let params = { investorId: this.userId };
      await this.apiService
        .postRequest(
          `investmentreport/investor/get-all-elegible-verbosed`,
          params
        )
        .then((result) => {
          this.listOfExtracts = result.content;
          this.listOfExtracts.forEach((element) => {
            element.text =
              this.$tc("company", 1) +
              ": " +
              element.companyName +
              " - " +
              this.$t("investment_vehicle") +
              ": " +
              element.investmentVehicleName +
              " - " +
              element.month +
              "/" +
              element.year;
          });
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
          return err;
        });
    },

    async reprocessExtracts() {
      this.reprocessExtractLoading = true;
      let params = this.selectedExtract;
      await this.apiService
        .postRequest(`investmentreport/process`, params)
        .then((result) => {
          this.$toast.success(this.$t("extract_reprocessed"));
          this.selectedExtract = null;
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        })
        .finally(() => {
          this.reprocessExtractLoading = false;
        });
    },

    async reprocessIRExtract() {
      this.reprocessExtractLoading = true;
      let params = {
        investorId: this.userId,
        reportType: 2,
        year: (new Date().getFullYear() -1).toString(),
      }
      await this.apiService
        .postRequest(`investmentreport/process`, params)
        .then((result) => {
          this.$toast.success(this.$t("extract_reprocessed"));
          this.selectedExtract = null;
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        })
        .finally(() => {
          this.reprocessExtractLoading = false;
        });
    },

    async getExtractsByPage() {
      this.loading = true;
      let currentPage = this.page;

      let params = { page: currentPage };

      if (this.userId) {
        params.investorId = this.userId;
      }

      if (this.companyId) {
        params.companyId = this.companyId;
      }

      if (this.reportType) {
        params.reportType = this.reportType;
      }

      await this.apiService
        .postRequest(`investmentreport/investor/report-list`, params)
        .then((result) => {
          this.page = result.content.currentPage;
          this.pageCount = Math.ceil(
            result.content.total / result.content.perPage
          );
          this.reports = result.content.items;
        })
        .catch((err) => {
          this.$toast.error(this.$t("error_occoured"));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDataAtual(Data) {
      return moment(Data).format("MMMM,YYYY");
    },
  },
};
</script>
<style scoped>
ul,
li {
  margin: 0;
  padding: 0;
}

.table-extract-td {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
}

.table-extract-td-vehicle {
  display: flex;
  align-items: center;
  gap: 20px;
}

.table-extract-td-link-download {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  display: block;
}

.table-icon-file {
  color: var(--primary);
  widows: 14px;
  height: 16px;
  cursor: pointer;
}

.extract-investor-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 10px;
}

.extract-investor-sub-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 20px;
}

.extract-table-thead {
  background: var(--primary);
}

.extract-table-thead > tr th {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 14px !important;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark) !important;
}

/* estilos mobile para a tela do investidor */
.extract-investor-vehicle {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
  display: block;
  margin-bottom: 8px;
}

.extract-investor-date {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: block;
  margin-bottom: 8px;
}

.extract-investor-download {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--primary);
  display: block;
  cursor: pointer;
}

.extract-investor-item {
  display: grid;
  justify-items: start;
  padding: 20px;
  border: 1px solid #e9e9e9;
  border-radius: 12px;
  cursor: pointer;
}

.extract-investor-list {
  display: none;
  margin-bottom: 20px;
}

.extract-investor-list li + li {
  margin-top: 20px;
}

.item-center {
  display: grid;
  place-items: center;
}

.reprocess-extract-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.reprocess-extract-input-container {
  width: 50%;
}

.reprocess-extract-button-container {
  margin-top: 4px;
}

@media (max-width: 769px) {
  .extract-table {
    display: none;
  }

  .extract-investor-list {
    display: initial;
  }
}
</style>
